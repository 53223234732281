import React from "react";
import LabeledSelect from "../../LabeledSelect";

type Props = {
  children?: React.ReactNode;
  onClick?: (...args: unknown[]) => unknown;
  onSelect?: (...args: unknown[]) => unknown;
  skeleton?: boolean;
  activeLink?: string;
  activeName?: string;
  testSelectorSelect?: string;
  testSelectorTitle?: string;
  label?: string;
};

function LocalNavigationSelect({
  children,
  onSelect,
  activeLink,
  activeName,
  label,
  skeleton,
  testSelectorSelect,
  testSelectorTitle,
  ...other
}: Props) {
  function onChangeSelect(event) {
    onSelect(event.target.value, event);
  }

  const optionGroups = [];
  React.Children.forEach(children, (OptionGroup: React.ReactElement, key) => {
    // eslint-disable-next-line react/no-array-index-key
    optionGroups.push(React.cloneElement(OptionGroup, { key }));
  });

  const testPropSelect = testSelectorSelect
    ? { [testSelectorSelect]: true }
    : null;
  const testPropTitle = testSelectorTitle
    ? { [testSelectorTitle]: true }
    : null;

  return (
    <LabeledSelect
      onChange={(event) => onChangeSelect(event)}
      value={activeLink}
      label={label}
      hideLabelOnSmallScreen
      skeleton={skeleton}
      {...testPropSelect}
      {...other}
    >
      {activeName ? (
        <option value="preselect" {...testPropTitle}>
          {activeName}
        </option>
      ) : null}
      {optionGroups}
    </LabeledSelect>
  );
}

LocalNavigationSelect.defautProps = {
  onClick: () => {},
};

export default LocalNavigationSelect;
