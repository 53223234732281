import React from "react";
import classNames from "classnames/bind";

import Text from "../../Text";
import s from "./LocalNavigationList.module.css";

const cx = classNames.bind(s);

type Props = {
  title?: string;
  link?: string;
  children?: React.ReactNode;
  onClick?: (...args: unknown[]) => unknown;
  skeleton?: boolean;
  testSelectorTitle?: string;
  testSelectorList?: string;
  contentType?: "CATEGORY_PRODUCT_NAVIGATION" | "CATEGORY_CONTENT_NAVIGATION";
};

function LocalNavigationList({
  title,
  link,
  children,
  onClick,
  skeleton,
  testSelectorTitle,
  testSelectorList,
  contentType,
}: Props) {
  const titleTestProps = testSelectorTitle
    ? { [testSelectorTitle]: true }
    : null;
  const listTestProps = testSelectorList ? { [testSelectorList]: true } : null;
  const listElements = [];
  React.Children.forEach(children, (Item: React.ReactElement, key) => {
    listElements.push(
      React.cloneElement(Item, {
        light: true,
        onClick,
        // eslint-disable-next-line react/no-array-index-key
        key,
      })
    );
  });

  function getClassName() {
    let className;
    if (contentType === "CATEGORY_PRODUCT_NAVIGATION") {
      className = s.product;
    } else if (contentType === "CATEGORY_CONTENT_NAVIGATION") {
      className = s.topic;
    } else {
      className = s.root;
    }
    return className;
  }

  return (
    <div className={getClassName()} {...listTestProps}>
      <Text normal>
        <span className={cx("title", { skeleton })} {...titleTestProps}>
          {link ? (
            <a
              href={link}
              onClick={(event) => onClick(event.currentTarget.origin, event)}
              data-prio2
            >
              {title}
            </a>
          ) : (
            title
          )}
        </span>
      </Text>
      <ul className={s.list}>{listElements}</ul>
    </div>
  );
}

export default LocalNavigationList;
