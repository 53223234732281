import React from "react";
import classNames from "classnames/bind";

import s from "./Item.module.css";

const cx = classNames.bind(s);

type Props = {
  link?: string;
  name?: string;
  active?: boolean;
  isOption?: boolean;
  disabled?: boolean;
  testSelectorLink?: string;
  testSelectorName?: string;
  onClick?: (...args: unknown[]) => unknown;
  skeleton?: boolean;
};

function Item({
  link,
  name,
  active,
  isOption,
  disabled,
  onClick,
  testSelectorLink,
  testSelectorName,
  skeleton,
}: Props) {
  const linkClasses = cx("link", { active, skeleton });
  const linkTestProps = testSelectorLink ? { [testSelectorLink]: true } : null;
  const nameTestProps = testSelectorName ? { [testSelectorName]: true } : null;
  const asListItem = (
    <li>
      <a
        href={link}
        className={linkClasses}
        {...linkTestProps}
        onClick={(event) => onClick(event.currentTarget.origin, event)}
      >
        <span className={s.name} {...nameTestProps}>
          {name}
        </span>
      </a>
    </li>
  );
  const asOption = (
    <option value={link} disabled={disabled} {...linkTestProps}>
      {name}
    </option>
  );
  return isOption ? asOption : asListItem;
}

Item.defaultProps = {
  onClick: () => {},
};

export default Item;
