import classNames from "classnames/bind";
import PropTypes from "prop-types";
/* eslint-disable react/no-array-index-key */
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import LocalNavigation from "stelar/components/LocalNavigation";
import Item from "stelar/components/LocalNavigation/Item";
import LocalNavigationList from "stelar/components/LocalNavigation/LocalNavigationList";
import LocalNavigationSelect from "stelar/components/LocalNavigation/LocalNavigationSelect";
import OptionGroup from "stelar/components/LocalNavigation/OptionGroup";
import { breakpoint } from "stelar/components/media";

import { triggerLocationChange } from "shared/utils/customEvents";
import useMediaQuery from "shared/utils/useMediaQuery";
import BottomDrawer from "../BottomDrawer";

import s from "./LocalNavigation.module.css";

const cx = classNames.bind(s);

function onLocalNaviClick(url, e) {
  if (e.metaKey || e.ctrlKey) {
    return null;
  }
  e.preventDefault();
  let path = "";
  if (e.target.nodeName === "SELECT") {
    const selectedTarget = e.target[e.target.selectedIndex];
    if (selectedTarget) {
      path = selectedTarget.value;
    }
  } else {
    path = e.currentTarget.pathname;
  }
  triggerLocationChange(path);
  return null;
}

function LocalNavigationSell({
  localNavis,
  parent,
  isContent,
  contentType,
  hasFacetFilters,
  moreThanOneNavigationList,
}) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isSmartphoneBreakpoint = useMediaQuery(
    `(max-width: ${breakpoint.s.max}px)`,
    false
  );
  const isDrawerEnabled = isSmartphoneBreakpoint;
  const largeNavigationLists = localNavis.filter(
    (item) =>
      item.type === "CATEGORY_ALL_NAVIGATION" ||
      item.type === contentType ||
      (item.type === "CATEGORY_CONTENT_NAVIGATION" &&
        hasFacetFilters &&
        !moreThanOneNavigationList)
  );
  const selectLabel =
    !isContent && localNavis[0] && localNavis[0].label
      ? localNavis[0].label
      : null;

  const onOpenDrawer = (e) => {
    e.preventDefault();
    setDrawerOpen(true);
  };

  const navigationDrawerHandler = isDrawerEnabled && {
    onClick: onOpenDrawer,
    onMouseDown: (e) => e.preventDefault(),
    onKeyDown: (e) => e.key === "Enter" && onOpenDrawer(e),
  };

  return (
    <LocalNavigation
      small={
        contentType !== "CATEGORY_CONTENT_NAVIGATION" ? (
          <>
            <LocalNavigationSelect
              activeLink="preselect"
              activeName={parent?.title || localNavis[0]?.title}
              onSelect={(url, event) => onLocalNaviClick(url, event)}
              testSelectorTitle="data-test-sell-mobile-local-navi-title"
              testSelectorSelect="data-test-sell-mobile-local-navi"
              label={selectLabel}
              {...navigationDrawerHandler}
            >
              {localNavis.map((localNavi, i) => (
                <OptionGroup title={localNavi.title} key={i}>
                  {localNavi.naviElements.map(({ name, link }, key) => (
                    <Item
                      name={name}
                      link={link}
                      key={key}
                      testSelectorLink="data-test-sell-local-navi-link"
                    />
                  ))}
                </OptionGroup>
              ))}
            </LocalNavigationSelect>
            <BottomDrawer
              lists={localNavis}
              parent={parent}
              opened={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            />
          </>
        ) : null
      }
      large={
        largeNavigationLists?.length > 0 ? (
          <div
            className={cx({
              bottom: hasFacetFilters || moreThanOneNavigationList,
            })}
          >
            {largeNavigationLists.map((localNavi, key1) => (
              <LocalNavigationList
                title={localNavi.title}
                link={localNavi.link}
                key={key1}
                testSelectorList="data-test-sell-local-navi"
                testSelectorTitle="data-test-sell-local-navi-title"
                onClick={onLocalNaviClick}
                light={!localNavi.toplevel}
                contentType={localNavi.type}
              >
                {localNavi.naviElements.map(({ name, link, active }, key2) => (
                  <Item
                    name={name}
                    link={link}
                    key={key2}
                    active={active}
                    testSelectorLink="data-test-sell-local-navi-link"
                    testSelectorName="data-test-sell-local-navi-link-text"
                  />
                ))}
              </LocalNavigationList>
            ))}
          </div>
        ) : null
      }
    />
  );
}

LocalNavigationSell.propTypes = {
  localNavis: PropTypes.array,
  parent: PropTypes.object,
  isContent: PropTypes.bool,
  contentType: PropTypes.string,
  hasFacetFilters: PropTypes.bool,
  moreThanOneNavigationList: PropTypes.bool,
};

export default LocalNavigationSell;
