import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import ContentHeading from "stelar/components/ContentHeading";
import s from "./ElementCategoryTeasers.module.css";
import CategoryTeaser from "../../Teasers/CategoryTeaser";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

const ElementCategoryTeasers = ({
  heading,
  teasers,
  fullWidth,
  onLinkClick,
  onImageLoad,
  loadedImages = {},
  lazy,
}) => (
  <ContentSpacing
    fullWidth={fullWidth}
    alignment="imagetext"
    data-test-sell-category-teasers
  >
    {heading && (
      <div className={s.heading}>
        <ContentHeading prio2>{heading}</ContentHeading>
      </div>
    )}
    {teasers &&
      teasers.map((teaser) => (
        <div className={s.teaser} key={teaser.id}>
          <CategoryTeaser
            {...teaser}
            fullWidth={fullWidth}
            onLinkClick={onLinkClick}
            onImageLoad={onImageLoad}
            loadedImages={loadedImages}
            lazy={lazy}
          />
        </div>
      ))}
  </ContentSpacing>
);

ElementCategoryTeasers.propTypes = {
  heading: PropTypes.string,
  teasers: PropTypes.array,
  fullWidth: PropTypes.bool,
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  lazy: PropTypes.bool,
};

export default ElementCategoryTeasers;
