import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LinkButton from "stelar/components/LinkButton";
import Text from "stelar/components/Text";
import Paragraph from "stelar/components/Paragraph";
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import { useTrackWhenViewed } from "shared/utils/useTrackWhenViewed";
import RatioImage from "../../RatioImage";

import s from "./CategoryTeaser.module.css";

const cx = classnames.bind(s);

function CategoryTeaser({
  button,
  fullWidth,
  heading,
  image = {},
  lazy,
  loadedImages = {},
  onImageLoad,
  onLinkClick,
  text,
  tracking,
}) {
  const { ref } = useTrackWhenViewed(tracking);

  const imgSizes = imageSizes({
    xxxl: "350px",
    xl: "33vw",
    l: fullWidth ? "33vw" : "25vw",
    m: "33vw",
    default: "100vw",
  });

  function onClick(e) {
    onLinkClick(e, tracking);
  }

  return (
    <div
      ref={ref}
      className={cx("root", { fullWidth, indented: !fullWidth })}
      data-test-sell-category-teaser
    >
      <a
        href={button && button.link}
        onClick={onClick}
        className={cx("column", "image")}
        tabIndex={-1}
        aria-hidden
      >
        {image && (
          <RatioImage
            image={image}
            imageProfileDefault={IMAGE_PROFILE_DEFAULTS.categoryteaser}
            lazy={lazy}
            sizes={imgSizes}
            onImageLoad={onImageLoad}
            hasLoaded={loadedImages[image.pattern]}
            data-test-sell-category-teaser-image
          />
        )}
      </a>
      <div className={cx("column", "text")}>
        {heading && (
          <div className={s.title}>
            <Text em tag="h3" data-test-sell-category-teaser-name>
              {button ? (
                <a href={button.link} data-prio2>
                  {heading}
                </a>
              ) : (
                heading
              )}
            </Text>
          </div>
        )}
        {text && <Paragraph>{text}</Paragraph>}
        {button && (
          <LinkButton
            em
            prio1
            href={button.link}
            onClick={onClick}
            tabIndex={-1}
            data-test-sell-category-teaser-link
          >
            {button.name}
          </LinkButton>
        )}
      </div>
    </div>
  );
}

CategoryTeaser.propTypes = {
  button: PropTypes.object,
  fullWidth: PropTypes.bool,
  heading: PropTypes.string,
  image: PropTypes.object,
  lazy: PropTypes.bool,
  link: PropTypes.object,
  loadedImages: PropTypes.object,
  onImageLoad: PropTypes.func,
  onLinkClick: PropTypes.func,
  text: PropTypes.string,
  tracking: PropTypes.string,
};

export default CategoryTeaser;
