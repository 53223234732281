import React from "react";
import LocalNavigation from "./LocalNavigation";
import LocalNavigationList from "./LocalNavigationList";
import LocalNavigationSelect from "./LocalNavigationSelect";
import Item from "./Item";

export default function SkeletonLocalNavigation() {
  return (
    <LocalNavigation
      small={<LocalNavigationSelect skeleton activeName="Gartenausstattung" />}
      large={
        <div>
          <LocalNavigationList skeleton title="Gartenausstattung">
            <Item skeleton name="Pflanz- & Blumenbänke" />
            <Item skeleton name="Zäune" />
            <Item skeleton name="Nist- und Futterplätze" />
            <Item skeleton name="Gartenhäuser" />
            <Item skeleton name="Aufbewahrung" />
            <Item skeleton name="Transport und Ernte" />
            <Item skeleton name="Vorratshaltung" />
          </LocalNavigationList>
          <LocalNavigationList skeleton title="Themen zu Garten:">
            <Item skeleton name="Dörren und trocknen" />
            <Item skeleton name="Erntezeit" />
            <Item skeleton name="Aufenthalt im Garten" />
            <Item skeleton name="Dresdner Gartenhaus" />
          </LocalNavigationList>
        </div>
      }
    />
  );
}
