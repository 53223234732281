import React from "react";

import s from "./LocalNavigation.module.css";

type Props = {
  small?: React.ReactNode;
  large?: React.ReactNode;
};

/**
 * The LocalNavigation contains a link list with headlines for large breakpoints and a dropdown for small breakpoints.
 */
function LocalNavigation({ small, large, ...args }: Props) {
  return (
    <div {...args} className={s.root}>
      <div className={s.small}>{small}</div>
      <div className={s.large}>{large}</div>
    </div>
  );
}

export default LocalNavigation;
