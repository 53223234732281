import React from "react";

type Props = {
  children?: React.ReactNode;
  title?: string;
};

function OptionGroup({ title, children }: Props) {
  const options = [];
  React.Children.forEach(children, (Item: React.ReactElement, key) => {
    // eslint-disable-next-line react/no-array-index-key
    options.push(React.cloneElement(Item, { isOption: true, key }));
  });
  return <optgroup label={title}>{options}</optgroup>;
}

export default OptionGroup;
