import classNames from "classnames/bind";
import { browserHistory } from "mf-react-router"; // eslint-disable-line import/no-extraneous-dependencies
import { breakpoint } from "stelar/components/media";
import track from "shared/tracking";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from "react-helmet";
import useMediaQuery from "shared/utils/useMediaQuery";
import { createStatefulHandleClick } from "shared/utils/routing";
import Breadcrumb from "../../../components/Breadcrumb";
import ContentKurator, {
  SkeletonContentKurator,
} from "../../../components/ContentKurator";
import ElementCategoryTeasers from "../../../components/ContentKurator/ElementCategoryTeasers";
import FacetFilter from "../../../components/FacetFilter";
import LocalNavigation, {
  SkeletonLocalNavigation,
} from "../../../components/LocalNavigation";
import ProductList from "../../../components/ProductList";
import ProductPager from "../../../components/ProductPager";
import RenderTarget from "../../../components/RenderTarget";
import SelectButton from "../../../components/SelectButton/SelectButton";
import SortSelect, { SkeletonSortSelect } from "../../../components/SortSelect";
import StickyBanner from "../../../components/StickyBanner";
import * as types from "../../constants";
import s from "./Category.module.css";

const cx = classNames.bind(s);

function Category({
  breadcrumb,
  kuratorContentsTop,
  kuratorContentsBottom,
  teaserSection,
  head,
  banner,
  loadedImages,
  localNavis: temporaryLocalNavis,
  onImageLoad,
  pager,
  sortOrder,
  params: { categoryId },
  currentLocation,
  products,
  facets,
  loading,
  routes,
  sliderState,
  sliderActions,
  productListState,
  productListActions,
  openedContentAccordionItems,
  closeOrOpenContentAccordionItem,
  setTrackingListType,
  infoContent,
  noCommercialTracking,
  initialFacetFilterDisplay,
  toggleListDisplay,
  closedFacetFilters,
  closeOrOpenFacetFilter,
}) {
  const isMobileBreakpoint = useMediaQuery(
    `(max-width: ${breakpoint.m.max}px)`,
    false
  );

  // TODO refactor: remove localNavis and use navigationLists and parentLink
  let localNavis;
  // let navigationLists;
  let parent;
  if (temporaryLocalNavis instanceof Array) {
    localNavis = temporaryLocalNavis;
  } else {
    // navigationLists = temporaryLocalNavis.navigationLists;
    localNavis = temporaryLocalNavis?.navigationLists;
    parent = temporaryLocalNavis?.parent;
  }

  const isContent = categoryId.startsWith("-");
  const isCategory = !isContent;
  const isFullWidth = !localNavis && !loading;
  const handleClick = createStatefulHandleClick({ categoryId }, routes);
  const hasFacetFilters = facets?.facets && facets.facets.length > 0;
  const moreThanOneNavigationList = localNavis && localNavis.length > 1;

  function handleClickAndTracking(e, trackingData) {
    setTrackingListType(trackingData);
    handleClick(e);

    // ToDo: we have to change tracking structure | SELL-4957
    if (Array.isArray(trackingData)) {
      trackingData.forEach((data) => {
        track(data);
      });
    } else {
      track(trackingData);
    }
  }

  function onClickBack(event) {
    event.preventDefault();
    browserHistory.goBack();
  }

  const kuratorContentProps = {
    categoryId,
    onLinkClick: handleClickAndTracking,
    loadedImages,
    onImageLoad,
    sliderState,
    sliderActions,
    closeOrOpenContentAccordionItem,
    openedContentAccordionItems,
    fullWidth: isFullWidth,
  };

  function getInfoContent() {
    return (
      <div>
        {infoContent.map((kuratorContent = {}, index) => (
          <ContentKurator
            content={kuratorContent}
            {...kuratorContentProps}
            isLazy={index > 1}
            key={`ContentKurator_info_${kuratorContent && kuratorContent.id}`}
          />
        ))}
      </div>
    );
  }

  function handleTracking(data) {
    track(data);
  }

  return (
    <div
      data-test-sell-category-page
      data-cookie-banner-area={!!noCommercialTracking}
    >
      <Helmet {...head} />

      <RenderTarget web>
        {breadcrumb && breadcrumb?.endpoint !== "no-breadcrumb" && (
          <Breadcrumb
            {...breadcrumb}
            reduced={loading}
            onClickBack={onClickBack}
            onLinkClick={handleClick}
          />
        )}
      </RenderTarget>

      <div className={s.main}>
        {banner ? (
          <StickyBanner
            banner={banner}
            handleTracking={handleTracking}
            onLinkClick={handleClickAndTracking}
          />
        ) : null}

        {infoContent && (
          <div
            className={cx(
              isFullWidth ? s.fullWidth : s.middle,
              s.infoContentTop
            )}
          >
            {getInfoContent()}
          </div>
        )}

        <div className={isFullWidth ? "" : s.left}>
          {localNavis ? (
            <LocalNavigation
              localNavis={localNavis}
              parent={parent}
              isContent={isContent}
              contentType={types.CATEGORY_PRODUCT_NAVIGATION}
              hasFacetFilters={hasFacetFilters}
              moreThanOneNavigationList={moreThanOneNavigationList}
            />
          ) : null}
          {loading && !localNavis ? <SkeletonLocalNavigation /> : null}
          {kuratorContentsTop && isMobileBreakpoint ? (
            <div className={s.kuratorTopMobile}>
              {kuratorContentsTop.map((kuratorContent = {}, index) => (
                <ContentKurator
                  content={kuratorContent}
                  {...kuratorContentProps}
                  isLazy={index > 1}
                  key={`ContentKurator_top_${
                    kuratorContent && kuratorContent.id
                  }`}
                />
              ))}
            </div>
          ) : null}
          {hasFacetFilters && (
            <FacetFilter
              url={currentLocation}
              facetFilters={facets}
              initialDisplay={initialFacetFilterDisplay}
              toggleListDisplay={toggleListDisplay}
              closeOrOpenFacetFilter={closeOrOpenFacetFilter}
              closedFacetFilters={closedFacetFilters}
            />
          )}
          {localNavis &&
            localNavis
              .filter(
                (item) =>
                  item.type === types.CATEGORY_CONTENT_NAVIGATION &&
                  (!hasFacetFilters || moreThanOneNavigationList)
              )
              .map(() => (
                <LocalNavigation
                  localNavis={localNavis}
                  isContent={isContent}
                  contentType={types.CATEGORY_CONTENT_NAVIGATION}
                />
              ))}
        </div>

        <div className={isFullWidth ? s.fullWidth : s.middle}>
          {infoContent && (
            <div className={s.infoContent} data-test-sell-info-content>
              {getInfoContent()}
            </div>
          )}
          {kuratorContentsTop && !isMobileBreakpoint
            ? kuratorContentsTop.map((kuratorContent = {}, index) => (
                <ContentKurator
                  content={kuratorContent}
                  {...kuratorContentProps}
                  isLazy={index > 1}
                  key={`ContentKurator_top_${
                    kuratorContent && kuratorContent.id
                  }`}
                />
              ))
            : null}

          {isCategory ? (
            <div className={s.topBar}>
              {pager ? (
                <div className={s.topPager}>
                  <ProductPager pager={pager} type="reduced" />
                </div>
              ) : null}
              <div className={s.sortOrder}>
                {sortOrder ? (
                  <SortSelect id="sortSelect" {...sortOrder} />
                ) : null}
                {loading && !sortOrder ? <SkeletonSortSelect /> : null}
                {initialFacetFilterDisplay && facets && (
                  <div className={s.filterButton}>
                    <SelectButton
                      onClick={toggleListDisplay}
                      className={cx({ hasBubble: facets.selectedCountSum })}
                      heading={facets && facets.heading}
                      count={facets && facets.selectedCountSum}
                      name="category-search"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div
            className={cx(s.products, {
              underlined: kuratorContentsBottom && !(isCategory && pager),
            })}
          >
            {products ? (
              <ProductList
                products={products}
                fullWidth={isFullWidth}
                onLinkClick={handleClickAndTracking}
                productListActions={productListActions}
                productListState={productListState}
              />
            ) : null}
          </div>

          {isCategory && pager && products ? (
            <div
              className={cx(s.bottomBar, {
                underlined: kuratorContentsBottom,
              })}
            >
              {pager ? <ProductPager pager={pager} type="detailed" /> : null}
            </div>
          ) : null}

          <div className={s.contents}>
            {kuratorContentsBottom
              ? kuratorContentsBottom.map((kuratorContent = {}, index) => (
                  <ContentKurator
                    content={kuratorContent}
                    {...kuratorContentProps}
                    isLazy={index > 1}
                    key={`ContentKurator_bottom_${
                      kuratorContent && kuratorContent.id
                    }`}
                  />
                ))
              : null}
            {loading && !kuratorContentsTop && !kuratorContentsBottom ? (
              <SkeletonContentKurator />
            ) : null}
            {teaserSection && (
              <ElementCategoryTeasers
                {...teaserSection}
                fullWidth={isFullWidth}
                onLinkClick={handleClick}
                onImageLoad={onImageLoad}
                loadedImages={loadedImages}
                lazy
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Category.propTypes = {
  banner: PropTypes.object,
  breadcrumb: PropTypes.object,
  kuratorContent: PropTypes.array,
  kuratorContentsTop: PropTypes.array,
  kuratorContentsBottom: PropTypes.array,
  teaserSection: PropTypes.object,
  head: PropTypes.object,
  loadedImages: PropTypes.object,
  localNavis: PropTypes.array,
  onImageLoad: PropTypes.func,
  pager: PropTypes.object,
  currentLocation: PropTypes.string,
  loading: PropTypes.bool,
  sortOrder: PropTypes.object,
  params: PropTypes.object,
  facets: PropTypes.object,
  products: PropTypes.array,
  routes: PropTypes.array,
  sliderState: PropTypes.object,
  sliderActions: PropTypes.object,
  productListState: PropTypes.object,
  productListActions: PropTypes.object,
  openedContentAccordionItems: PropTypes.object,
  closeOrOpenContentAccordionItem: PropTypes.func,
  setTrackingListType: PropTypes.func,
  infoContent: PropTypes.array,
  noCommercialTracking: PropTypes.bool,
  initialFacetFilterDisplay: PropTypes.bool,
  toggleListDisplay: PropTypes.func,
  closedFacetFilters: PropTypes.object,
  closeOrOpenFacetFilter: PropTypes.func,
  onClickBack: PropTypes.func,
};

Category.defaultProps = {
  head: {},
  loading: false,
  params: {
    categoryId: "",
  },
};

export default Category;
